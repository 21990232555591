export default [
  {
    path: '/event/:eventId/attendance',
    name: 'event.attendance.index',
    component: () => import('@/views/event/attendance/index.vue'),
  },
  {
    path: '/event/:eventId/attendance/:userId/edit',
    name: 'event.attendance.edit',
    component: () => import('@/views/event/attendance/edit.vue'),
  },
]
