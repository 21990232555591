import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
    // baseURL: 'https://cra-api-qa.kskgroup.com/',
    baseURL: 'https://lead-api.kskgroup.com/',
  // timeout: 1000,
    withCredentials: true,
    headers: {
      // Authorization: "Bearer 2|nkHBLvLF6H2HqSfmkWTlUWJmxIg7NU2nLbl4OACQ",
        // 'Accept': 'application/json',
        // 'X-Requested-With' : 'XMLHttpRequest',
    }
})

axiosIns.defaults.withCredentials = true

Vue.prototype.$http = axiosIns

export default axiosIns
