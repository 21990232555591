export default [
  {
    path: '/event',
    name: 'event.index',
    component: () => import('@/views/event/EventListingTable.vue'),
  },
  {
    path: '/event/create',
    name: 'event.create',
    component: () => import('@/views/event/form/EventForm.vue'),
  },
  {
    path: '/event/:eventId/edit',
    name: 'event.edit',
    component: () => import('@/views/event/form/EventForm.vue'),
  },
]
